.mobile-verification {
  width: 100%;
  height: 100vh;
  max-width: 360px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  &__powered-by {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  &__powered-by-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #747e99;
    border-right: 2px solid var(--color-input-border-light);
    padding-right: 8px;
  }
  &__powered-by-img {
    width: 60px;
    padding-left: 8px;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
  }
  &__row {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__row-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--color-text-90-light);
  }
  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--color-text-110-light);
  }
  &__number-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 56px;
    height: 56px;
    background: #f2f4f7;
    border: 1px solid var(--color-input-border-light);
    border-radius: 32px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #1b1c1f;
    cursor: pointer;
    &--active {
      border-color: var(--color-primary-dark);
    }
  }
  &__cancel-btn {
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--color-text-110-light);
    font-size: 14px;
    line-height: 20px;
    border: 1px solid var(--color-text-110-light);
    border-radius: 4px;
    margin-top: 48px;
  }
  &__success {
    img {
      height: 220px;
    }
  }
  &__success-message {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 28px;
  }
  &__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  &__svg-height {
    height: 100px;
  }
}

.MobileApprove {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 96vh;
  gap: 24px;
  &__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: var(--color-text-90-light);
  }
  &__logo {
    width: 144px;
    height: 144px;
    object-fit: contain;
  }
  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--color-text-60-light);
  }
  &__btn-row {
    gap: 8px;
  }
  &__btn {
    margin: unset;
    border: 1px solid var(--color-gray-dark-100);
  }
}

.mobile-verification__success {
  height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-verification__error {
  height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}