.NotFoundError__container {
  &--image {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    img {
      height: 160px;
    }
    &--title {
      font-size: 14px;
    }
    h4 {
      text-align: center;
    }
  }
}
