@use "utils";
@use "./variables/border-radius";
@use "./variables/colors";
@use "./variables/font-size";
@use "./variables/padding";
@use "./variables/margin";
@use "./variables/line-heights";

@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Great+Vibes&family=Homemade+Apple&family=Inspiration&family=Kristi&family=Monsieur+La+Doulaise&family=Reenie+Beanie&family=Sacramento&family=Zeyada&display=swap");

* {
  text-align: left;
  box-sizing: border-box;
}

:after,
:before {
  box-sizing: border-box;
}
// css used from bootstrap
body {
  margin: 0;
  line-height: 1.5;
  color: #495057;
  font-size: 0.9375rem;
  font-weight: 400;
  background-color: #fff;
  letter-spacing: 0.02rem;
  padding: 0;
  font-family: Poppins, sans-serif;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
p {
  margin-bottom: 1rem;
  margin-top: 0;
}

img,
svg {
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  caption-side: bottom;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

.hover {
  @include utils.transition();
}

.hover:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
