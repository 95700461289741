.circle-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.loader-blue {
  border: 3px solid #458bf5;
  border-top: 3px solid #f5f8ff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
