.InvalidCode--conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  img {
    height: 100px;
  }
  &__btn {
    width: 100px;
    justify-content: center;
    color: #515767;
    font-weight: 400;
    margin-top: 20px;
  }
}
