:root {
  --font-size-1: 10px;
  --font-size-2: 12px;
  --font-size-3: 14px;
  --font-size-4: 16px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 32px;
}

:root.font-large {
  --font-size-1: 12px;
  --font-size-2: 14px;
  --font-size-3: 16px;
  --font-size-4: 18px;
  --font-size-5: 22px;
  --font-size-6: 26px;
  --font-size-7: 34px;
}
:root.font-small {
  --font-size-1: 8px;
  --font-size-2: 10px;
  --font-size-3: 12px;
  --font-size-4: 14px;
  --font-size-5: 16px;
  --font-size-6: 20px;
  --font-size-7: 24px;
}
