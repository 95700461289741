.Offline {
  &__container {
    width: 360px;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    &--btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-bg-100-light);
      cursor: pointer;
    }
  }
  &__image {
    width: 192px;
    height: 192px;
  }
  &__text {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: var(--color-black);
  }
  &__desc {
    color: var(--color-text-130-light);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
